import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'

class VideoHalf extends Component {
  render() {

    return (
        <div className="right-half">
	        <img src={this.props.image} />
        </div>

    )
  }
}

export default VideoHalf