import React from 'react';
import { select } from 'd3';

class Line extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
  }
  componentDidMount() {
    const node = this.ref.current;
    const { data, lineGenerator, uniqueid } = this.props;

    const initialData = data.map(d => ({
      date: d.date,
      value: d.value
    }));



    select(node)
      .append('path')
      .datum(initialData)
      .attr('id', uniqueid)
      .attr('stroke', '#3285b9')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('d', lineGenerator);

    this.updateChart();
  }
  componentDidUpdate() {
    this.updateChart();
  }
  updateChart() {
    const {
          lineGenerator, data, uniqueid
        } = this.props;


    const line = select('#' + uniqueid);

    line
      .datum(data)
      .attr('d', lineGenerator);
  }
  render() {
    return <g className="line-group" ref={this.ref} />;
  }
}

export default Line;
