import firebase from 'firebase'
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyCbSyj7x2dmYl3q9v52kYmclodexJOGOLE",
    authDomain: "the-brief-app.firebaseapp.com",
    databaseURL: "https://the-brief-app.firebaseio.com",
    projectId: "the-brief-app",
    storageBucket: "the-brief-app.appspot.com",
    messagingSenderId: "872201352566"
};

firebase.initializeApp(config);

const firestore = firebase.firestore();
const settings = {/* your settings... */ timestampsInSnapshots: true};
firestore.settings(settings);

export default firebase;