import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Post extends Component {
  constructor(props) {
      super(props);
      this.state = {
        posts: props.display,
        postid: props.postid
      }

    }


  createMarkup(string) {
    return {__html: string};
  }


  render() {
    var postType = "alex-is-blank " + this.state.posts[this.props.postid].type;

    return (
      <div>
          <Link to="/" className="alex-is">Alex Stillwell<br/>
          </Link>
          <div className={postType} dangerouslySetInnerHTML={this.createMarkup(this.state.posts[this.props.postid].title)}></div>
      </div>
    )
  }
}

export default Post
