import React, { Component } from 'react';
import { scaleLinear, scaleTime, line, extent, min, max, curveStepAfter } from "d3";
import firebase from '../../firebase.js'; 
import Line from '././Line';


class Dogslog extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      data: [],
      lastdata: []
    }
   var db = firebase.firestore(); 

   db.collection("stats").doc("static").collection("number_of_dogs_log").orderBy('date', 'asc').get().then((querySnapshot) => {
       querySnapshot.forEach((doc) => {
         var data = doc.data();
         this.setState(state => (
           { 
             data: this.state.data.concat(data),
             lastdata: data
           }
         ))
       });
   }).then(() => {
      var todaysDate = Date.now()/1000;
      var todayData = {date: todaysDate, count: this.state.lastdata.count };
      this.setState(state => (
         { 
            data: this.state.data.concat(todayData),
         }
      )) 
   });   

  }
   render() {
    const { data } = this.state;
    const parentWidth = 300;

    const margins = {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    };

    const width = parentWidth - margins.left - margins.right;
    const height = 80 - margins.top - margins.bottom;

    const xScale = scaleTime()
      .domain([new Date(min(data, d => d.date)), new Date(Date.now()/1000)])
      .range([0, width]);

    const yScale = scaleLinear()
      .domain(extent(data, d => d.count))
      .range([height, 0])

    const lineGenerator = line()
      .x(d => xScale(d.date))
      .y(d => yScale(d.count))
      .curve(curveStepAfter)

    return (
      <div>
        <svg
          className={this.props.log}
          width={width + margins.left + margins.right}
          height={height + margins.top + margins.bottom}
        >
          <g transform={`translate(${margins.left}, ${margins.top})`}>
            <Line uniqueid={"dogslog"} data={data} xScale={xScale} yScale={yScale} lineGenerator={lineGenerator} width={width} height={height} />
          </g>
        </svg>
      </div>
    );
  }

}
export default Dogslog
