import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'

class Righthalf extends Component {
  render() {

    return (
        <div className="right-half">
          <img alt="associated with text" src={this.props.image} /> 
        </div>

    )
  }
}

export default Righthalf