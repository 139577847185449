import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import firebase from '../firebase.js'; 

class Perm extends Component {
  constructor() {
    super();
    this.state = {
      perm: [],
      loaded: false,
      images: [],
      displayImage: [],
      currentPost: null
    } 
    var db = firebase.firestore(); 

    db.collection("perm").doc("about").get().then(doc => {
        var data = doc.data();
        this.setState(state => (
          { 
            perm: data,
            loaded: true
          }
        ))
    });
  }

  createMarkup(string) {
    return {__html: string};
  }


  render() {
    var postType = "alex-is-blank " + this.state.perm.type;

    return (
      <div>
          <Link to="/" className="alex-is">⭠</Link>
          <div className={postType} dangerouslySetInnerHTML={this.createMarkup(this.state.perm.title)}></div>
      </div>
    )
  }
}

export default Perm
