import React, { Component } from 'react';
import { Route, Link, Switch } from 'react-router-dom'

import firebase from './firebase.js'; 

import Post from './components/Post'
import Perm from './components/Perm'
import Counter from './components/Counter'
import Righthalf from './components/Righthalf'
import Videohalf from './components/Videohalf'

class App extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      loaded: false,
      images: [],
      currentPost: null
    }
 

    var db = firebase.firestore(); 

    db.collection("items").orderBy('time', 'desc').get().then((querySnapshot) => {
        var count = 0;
        querySnapshot.forEach((doc) => {
          var data = doc.data();
          data.id = doc.id;
          data.count = count;
          count += 1;
          this.setState(state => (
            { 
              posts: this.state.posts.concat(data),
            }
          ))
        });
        this.setState({ 
          loaded: true
        })
    });
  }

  render() {
    if (!this.state.loaded) {
        return <div>Loading...</div>;
    } else {    

      return (
        <div className="appe">
        <Switch>
          <Route exact path='/' render={() => (
            <div>
              <div className="left-half">
                <div className="alex-is-blank main"><h1>Alex Stillwell</h1>Designer, very serious</div>              
                <div className="next">
                  <Link to='/about'>About</Link>
                  <Link to='/stats'>Stats</Link>
                </div>
              </div>
              <Videohalf key={Date.now()} image="https://firebasestorage.googleapis.com/v0/b/the-brief-app.appspot.com/o/Doggos.gif?alt=media&token=674fa2a5-cd7a-41f1-a66f-f4e02ce90294" />
            </div>
          )} />

          <Route exact path='/about' render={() => (
            <div>
              <div className="left-half">
                <Perm />
              </div>
              <Videohalf key={Date.now()} image={"https://firebasestorage.googleapis.com/v0/b/the-brief-app.appspot.com/o/SanFrancisco.gif?alt=media&token=43a2cee1-6adf-4dd0-9fc8-058b3822bf85"} />
            </div>
          )} />   

          <Route exact path='/stats' render={() => (
            <div>
              <div className="left-half">
                <Counter />
              </div>
              <Videohalf key={Date.now()} image={"https://firebasestorage.googleapis.com/v0/b/the-brief-app.appspot.com/o/munich.gif?alt=media&token=5774d5fb-bd65-484f-8ee4-83ae9a409c68"} />
            </div>
          )} />          
                    
          <Route exact path='/:id' render={({ match }) => (
            <div>
              <div className="left-half">
                <Post display={this.state.posts} postid={match.params.id} />
              </div>
              {this.state.posts[match.params.id].image ? (
                <Righthalf image={this.state.posts[match.params.id].image} />
              ) : (
                <Righthalf image={this.state.displayImage.url}  />
              )}
            </div>
          )} />
        </Switch>
      </div>

      );
    }
  }
}

export default App