import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import firebase from '../firebase.js'; 
import Countrieslived from '../components/charts/Countrieslived'
import Citieslived from '../components/charts/Citieslived'
import Dogslog from '../components/charts/Dogslog'
import Stateslived from '../components/charts/Stateslived'
import Countriesvisited from '../components/charts/Countriesvisited'
import Patentslog from '../components/charts/Patentslog'


class Counter extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      strava: [],
      displayImage: [],
      currentPost: null,
      static: [],
      spotify: [],
      dates: [],
      location: null
    } 
    var db = firebase.firestore(); 

      db.collection("stats").get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var returnObj = {};
            returnObj[doc.id] = doc.data();
            this.setState( returnObj )

          });
      }); 
      db.collection("location").orderBy("id", "desc").limit(1).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
          var data = doc.data();
          this.setState(state => (
            { 
              location: data,
              loaded: true
            }
          ))
          });
      });          

  }

  createMarkup(string) {
    return {__html: string};
  }

  toTodayInYears(date) {
    if (date) {
      var secondsNow = Date.now()/1000;
      var elapsedTime = secondsNow - date;
      var timeInYears = elapsedTime / 31536000; 
      return (this.roundToOne(timeInYears));
    } else {
      return "0";
    }
  }
  toTodayInDays(date) {
    if (date) {
      var secondsNow = Date.now()/1000;
      var elapsedTime = secondsNow - date;
      var timeInDays = elapsedTime / 86400; 
      return (this.roundToZero(timeInDays));
    } else {
      return "0";
    }
  }  
  roundToZero(num) {    
    return +(Math.round(num));
  }
  roundToOne(num) {    
    return +(Math.round(num + "e+1")  + "e-1");
  }  
  roundToTwo(num) {    
    return +(Math.round(num + "e+2")  + "e-2");
  }
  getCityInfo(locations) {
    console.log(locations);
    // var locationLength = locations.slice(-1)[0];
    // var city = locations[0].city;
    // var state = locations[0].state;
    var city = "Denver";
    var state = "Colorado";
    return (city + " " + state);
  }

  render() {
    if (this.state.loaded) {
    return (
      <div>
        <Link to="/" className="alex-is">⭠</Link>
        <div className="alex-is-blank long wide">
          <h1>In stats</h1>
          <div className="stats">
            <div className="stat">
              <Citieslived />
              <div className="number">
                {this.state.static.cities_lived}
              </div>
              <div className="desc">
               Cities lived
              </div>
            </div>
            <div className="stat">
              <Countrieslived />
              <div className="number">
                {this.state.static.countries_lived}
              </div>
              <div className="desc">
                Countries lived
              </div>
            </div>
            <div className="stat">
              <Countriesvisited />
              <div className="number">
                {this.state.static.countries_visited}
              </div>
              <div className="desc">
               Countries visited
              </div>
            </div>
            <div className="stat">
              <Stateslived />
              <div className="number">
                {this.state.static.states_lived}
              </div>
              <div className="desc">
               States Lived
              </div>
            </div>  
            <div className="stat">
              <Dogslog />
              <div className="number">
                {this.state.static.number_of_dogs}
              </div>
              <div className="desc">
                Doggos
              </div>
            </div>  
            <div className="stat">
              <Patentslog />
              <div className="number">
                {this.state.static.number_of_patents}
              </div>
              <div className="desc">
                Number of patents
              </div>
            </div>  
            <div className="stat">
              <div className="number">
                {this.state.strava.miles_year}
              </div>
              <div className="desc">
                Miles cycled the past year
              </div>
            </div>  
            <div className="stat">
              <div className="number">
                {this.state.strava.miles_lastride}
              </div>
              <div className="desc">
                Last ride
              </div>
            </div>  
            <div className="stat">
              <div className="number">
                {this.state.strava.miles_farthestride}
              </div>
              <div className="desc">
                Longest ride
              </div>
            </div>  
            <div className="stat">
              <div className="number">
                {this.toTodayInYears(this.state.dates.birthday)}
              </div>
              <div className="desc">
                Age (years)
              </div>
            </div>  
            <div className="stat">
              <div className="number">
                {this.toTodayInDays(this.state.dates.birthday)}
              </div>
              <div className="desc">
                Age (days)
              </div>
            </div>  
            <div className="stat">
              <div className="number">
                {this.toTodayInDays(this.state.dates.wedding)}
              </div>
              <div className="desc">
                Days married
              </div>
            </div>  
            <div className="stat">
              <div className="number">
                {this.toTodayInYears(this.state.dates.college_end)}
              </div>
              <div className="desc">
                Years since college
              </div>
            </div>  
            <div className="stat">
              <div className="number">
                {this.toTodayInYears(this.state.dates.google)}
              </div>
              <div className="desc">
                Years at Google
              </div>
            </div>  
            <div className="stat">
              <div className="text">
                {this.state.spotify.newest_liked_song}
              </div>
              <div className="desc">
                Latest song
              </div>
            </div>  
            <div className="stat">
              <div className="text">
                {this.state.location.city}<br/>{this.state.location.state}
              </div>
              <div className="desc">
                <div className="small"> Current location</div>
              </div>
            </div>
          </div>         
        </div>
      </div>
    )
  } else {
    return (
      <div className="loading"> Just a moment </div>
    );
  }
}}

export default Counter
